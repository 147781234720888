import React from 'react';
import { Layout } from '../../components/Layout';
import img1 from '../../images/articles/overland-drainage-right-of-way.jpg';
import img2 from '../../images/articles/odrw-plan10.jpg';
import img3 from '../../images/articles/odrw-drain-backyard.jpg';

const OverlandDrainageRightOfWays = () => (
  <Layout
    title="RPR, Real property Report, blog entry with detailed referenced information on Air Conditioning and window wells survey requirements"
    description="Detailed blog entry discussing issues with RPR, Real Property Reports relating to Window Wells and Air Conditioning units and municipal bylaws."
    keywords=""
  >
    <main>
      <h1>Overland Drainage Right of Ways</h1>
      <p>What is an Overland Drainage Right of Way</p>

      <div className="text-center my-4">
        <img src={img1} alt="Encumbrances, Liens & Interests" width={800} height={400} />
      </div>

      <p>
        A <strong>Right of Way (R.W.)</strong> is a type of agreement that is registered on a{' '}
        <strong>Land Title</strong>, the document that specifies the ownership and extent of rights
        of a specific parcel of land. Right of ways specify by description, or by referenced survey
        plan, a portion of a parcel of land that has unique restrictions or usage rights to be
        exercised and enforced by the grantee.
      </p>

      <p>
        An <strong>Overland Drainage Right of Way (O.D.R.W.)</strong> is a common type of Right of
        way that is registered on a parcel’s title and grants the Grantee the right to drain water
        from and across the parcel, as well as to install structures to facilitate this drainage
        such as concrete swales. In most cases this same agreement generally prohibits inhibiting
        the drainage of water, and explicitly the installation of other structures on the right of
        way area. This is a notable restriction to land owners rights to use their land and should
        be appreciated.
      </p>

      <p>
        A real Property Report will show location of the right of way, usually noting it as an
        O.D.R.W. short of Overland Drainage Right of Way. Along with this notation will almost
        always reference its width and a plan registered at the land titles office indicating its
        position.
      </p>

      <div className="text-center my-4">
        <img src={img2} alt="RPR with overland drainage right of way" width={800} height={400} />
      </div>

      <p>
        In Calgary, Red Deer and neighbouring municipalities O.D.R.W.’s generally occupy 2.40 metres
        at the rear property line of the parcel. It is not common, but they can also be located
        along the side of the parcel. Older parcels, using the original design, will be symmetric
        with 1.20 metres of the right of way on each side of the property line, for a total of 2.40
        metres. Newer designs are asymmetric on each side of the property line, 1.80 metres on the
        property containing the drainage improvement (usually a concrete swale) and 0.60 metres on
        the property adjacent and opposite. While the O.D.R.W. grants the municipality the right to
        maintain the swale if necessary, in Alberta it is normally the responsibility of the
        property owner to maintain the concrete swale and keep it clear of blockages at all times.
      </p>

      <p>
        It is a common frustration for land owners to have this area at the back of their property
        that they should not install structures on, as it is a natural place to put sheds. One
        should be prepared to either move the structures or get encroachment agreements (if they
        can) with the municipal authority when selling their house if they have used this area for
        decks, patios, retaining walls, sheds, or other structures. It should also be noted these
        above agreements, if they are attainable at all, will cause delays, often significant ones,
        in the real estate sale, and homeowners should be aware of and prepared for this as well.
      </p>

      <div className="text-center my-4">
        <img src={img3} alt="Backyard overland drain" width={745} height={400} />
      </div>
    </main>
  </Layout>
);

export default OverlandDrainageRightOfWays;
